<script>
import { useCartFunctions } from '@/composables/useCartFunctions';
import { frameworkContentKey } from '@/keys';
import QuickCartRow from './QuickCartRow';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    QuickCartRow,
  },
  inject: {
    frameworkContent: {
      from: frameworkContentKey,
    },
  },
  setup() {
    const { isCartOpen } = useCartFunctions();
    const { websiteText } = useWebsiteTexts();

    return {
      isCartOpen,
      label: 'Buylabel',
      websiteText,
    };
  },
  computed: {
    rows() {
      return this.$cart.rows;
    },
    total() {
      return this.$formatPrice(this.$cart.grandTotal);
    },
    currencyId() {
      return this.$cart.currency.id;
    },
    countryId() {
      return this.$cart.countryId;
    },
    cartUsp() {
      return this.$channel.cartUsp;
    },
    checkoutUrl() {
      return this.frameworkContent.CheckOutPage?.url;
    },
    emptyCart() {
      return this.$cart.rows?.length === 0 || false;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleDocumentKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
  },
  methods: {
    closeCart() {
      this.isCartOpen = false;
    },
    handleDocumentKeyDown(e) {
      if (e.key !== 'Escape') return;
      this.isCartOpen = false;
    },
    onGoToCheckout() {
      try {
        let path = this.$route.pathname;
        if (this.$route.search) {
          path = `${path}?${this.$route.search}`;
        }
        localStorage.setItem('checkoutPreviousRoute', path);
      } catch { /* ignore */ }

      this.closeCart();
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.closeCart();
      },
    },
  },
};
</script>

<template>
  <div class="quick-cart__container" v-if="isCartOpen" ref="root">
    <div class="quick-cart__background" @click="isCartOpen = false"></div>
    <div class="quick-cart">
      <div class="quick-cart_summary-small">
        <div
          v-if="emptyCart"
          class="quick-cart_empty-title-small"
        >{{ websiteText('header_shoppingcartisempty').value }}</div>

        <div v-if="!emptyCart">
          <div class="quick-cart_summary-text-small">{{ websiteText('header_ordertotal').value }}</div>
          <div class="quick-cart_summary-amount-small">{{ total }}</div>
        </div>

        <router-link
          v-if="!emptyCart"
          @click.native="onGoToCheckout()"
          class="quick-cart_buy-now-small"
          :to="checkoutUrl"
        >{{ websiteText('header_buynow').value }}</router-link>
      </div>

      <div class="quick-cart_usp-mobile">{{ cartUsp }}</div>

      <div class="quick-cart_title">
        {{ websiteText('header_shoppingcart').value }}
        <a class="quick-cart_close" @click="closeCart()">✕</a>
      </div>

      <ul class="quick-cart_list">
        <quick-cart-row v-for="row in rows" :row="row"></quick-cart-row>
      </ul>
      <div class="quick-cart_summary">
        <div
          v-if="emptyCart"
          class="quick-cart_empty-title"
        >{{ websiteText('header_shoppingcartisempty').value }}</div>

        <div v-if="!emptyCart" class="quick-cart_summary-wrap">
          <div class="quick-cart_summary-text">{{ websiteText('header_ordertotal').value }}</div>
          <div class="quick-cart_summary-amount" data-cy="quickcart-total">{{ total }}</div>
        </div>

        <router-link
          v-if="!emptyCart"
          class="quick-cart_buy-now"
          @click.native="onGoToCheckout()"
          :to="checkoutUrl"
        >{{ websiteText('header_buynow').value }}</router-link>
      </div>

      <div
        v-if="!emptyCart"
        class="quick-cart_usp"
        data-cy="quick-cart-usp"
      >{{ cartUsp }}</div>
    </div>
  </div>
</template>

<style>
.quick-cart__container {
  max-width: var(--layout-maxWidth);
  margin: 0 auto;
  position: relative;
}

.quick-cart__background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.quick-cart {
  width: 100%;
  right: 0;
  background: var(--color-white);
  max-height: calc(100vh - 47px);
  color: var(--color-coldGrey200);
  border-top: 1px solid var(--color-grey900);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 16px rgba(26, 26, 26, 0.05);
  z-index: 2;
}

@media(--phone) {
  .quick-cart {
    position: absolute;
  }
}

.quick-cart_summary-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid var(--color-grey900);
}

.quick-cart_empty-title-small {
  font-family: var(--font-header);
}

.quick-cart_summary-text-small {
  font-family: var(--font-header);
  line-height: 24px;
}

.quick-cart_summary-amount-small {
  font-family: var(--font-body);
  line-height: 24px;
}

.quick-cart_buy-now-small {
  display: flex;
  width: 174px;
  height: 36px;
  padding: 6px 0;
  font-family: var(--font-proxima-nova-regular);
  background: var(--color-black);
  color: var(--color-white);
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.quick-cart_buy-now-small:hover {
  color: var(--color-white);
  background-color: var(--color-neutral-100);
}

.quick-cart_title {
  font-family: var(--font-proxima-nova-semi-bold);
  font-size: 18px;
  padding: 10px 0;
  position: relative;
  display: none;
}

.quick-cart_usp-mobile {
  display: block;
  font-family: var(--font-proxima-nova-semi-bold);
  text-align: center;
  padding-top: 10px;
}

.quick-cart_usp {
  display: none;
}

.quick-cart_close {
  position: absolute;
  right: 0;
  font-family: var(--font-body);
}

.quick-cart_list {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 0 15px 10px;
  margin: 0;
}

.quick-cart_summary {
  display: none;
}

.quick-cart_summary-empty {
  padding: 10px 20px 20px 20px;
}

.quick-cart_summary-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.quick-cart_summary-wrap div {
  font-family: var(--font-proxima-nova-semi-bold);
  margin: 10px 0;
}

.quick-cart_summary-text {
  width: 50%;
  font-size: 20px;
}

.quick-cart_summary-amount {
  font-size: 22px;
  width: 50%;
  text-align: right;
}

.quick-cart_buy-now {
  display: block;
  width: 100%;
  font-family: var(--font-proxima-nova-regular);
  background: var(--color-black);
  color: var(--color-white);
  text-align: center;
  text-decoration: none;
  line-height: 24px;
  height: 35px;
  padding: 5px 0px;
}

.quick-cart_buy-now:hover {
  color: var(--color-white);
  background-color: var(--color-neutral-100);
}

.quick-cart_empty-title {
  display: block;
  font-family: var(--font-header);
  width: 100%;
}

.quick-cart-row:last-child {
  border-bottom: none;
}

@media (--tabletAndDesktop) {
  .quick-cart {
    width: 440px;
    position: absolute;
    right: 0;
    max-height: calc(100vh - 66px);
    overflow: hidden;
    padding: 10px 20px 0 20px;
    border: 2px solid #f1f1f1;
    border-top-width: 1px;
  }

  .quick-cart_usp-mobile {
    display: none;
  }

  .quick-cart_usp {
    display: block;
    text-align: center;
    padding: 1rem 0;
  }

  .quick-cart_summary-small {
    display: none;
  }

  .quick-cart_title {
    display: block;
  }

  .quick-cart_summary {
    display: block;
  }

  .quick-cart_list {
    padding: 0 10px 10px 0;
  }
}
</style>
