<script>
export default {
  props: {
    expanded: {
      type: Boolean,
    },
  },
  computed: {
    direction() {
      return this.expanded ? 'up' : 'down';
    },
    rotation() {
      switch (this.direction) {
        case 'right':
          return -90;
        case 'left':
          return 90;
        case 'up':
          return -180;
        case 'down':
          return 0;
      }
    },
    style() {
      return {
        transform: `translateY(-70%) rotate(${this.rotation}deg)`,
      };
    },
  },
};
</script>

<template>
  <span class="arrow-expandable">
    <svg
      :style="style"
      class="arrow"
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 6L11 1"
        class="stroke"
        stroke="black"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<style>
.arrow-expandable {
  height: 21px;
  width: 20px;
  margin-left: 0.5rem;
  display: inline-block;
}

.arrow {
  position: relative;
  top: 50%;
  width: inherit;
  height: inherit;
}

.fill {
  fill: white;
}

.stroke {
  stroke: white;
}
</style>
