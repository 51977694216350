<script>
import { OptionsComponent } from '@drapejs/core';
import { searchStringKey } from '@/keys';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  extends: OptionsComponent,
  props: {
    focus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchTimeout: null,
  }),
  inject: {
    searchString: searchStringKey,
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  watch: {
    searchString(newValue, oldValue) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.$mitt.emit('perform-quick-search', newValue);
      }, 300);
    },
  },
  methods: {
    selectText() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.select();
      }
    },
    cancelSearch() {
      this.searchString = '';
      this.$refs.searchInput.focus();
    },
    triggerFullSearch() {
      if (!this.searchString) return;
      this.$mitt.emit('perform-full-search', this.searchString);
    },
    onEnterKeyPressed() {
      this.$refs.searchInput.blur();
      this.triggerFullSearch();
    },
    clearSearchInput() {
      this.searchString = '';
    },
    focusSearchInput() {
      this.$refs?.searchInput?.focus();
    },
    blurSearchInput() {
      this.$refs?.searchInput?.blur();
    },
  },
  mounted() {
    if (this.focus) {
      this.focusSearchInput();
    }

    this.$mitt.on('focus-search-input', this.focusSearchInput);
    this.$mitt.on('clear-search-input', this.clearSearchInput);
    this.$mitt.on('blur-search-input', this.blurSearchInput);
  },
  beforeUnmount() {
    this.$mitt.off('focus-search-input', this.focusSearchInput);
    this.$mitt.off('clear-search-input', this.clearSearchInput);
    this.$mitt.off('blur-search-input', this.blurSearchInput);
  },
};
</script>

<template>
  <div class="search-view-input">
    <div class="search-view-input__wrapper">
      <input
        type="text"
        class="search-view-input__input"
        ref="searchInput"
        v-model="searchString"
        :placeholder="websiteText('global__search__placeholder').value
          || 'global__search__placeholder'
        "
        @focus="selectText()"
        @blur="$mitt.emit('search-performed')"
        @keyup.enter="onEnterKeyPressed"
      />
      <!-- <img
        class="search-view-input__delete-icon"
        src="../../static/icons/clear_search_icon.svg"
        @click="cancelSearch()"
        v-if="searchStringModel"
      /> -->
      <img
        class="search-view-input__search-input-icon"
        src="../../static/icons/search_icon.svg"
        @click="triggerFullSearch()"
      />
    </div>
  </div>
</template>

<style>
.search-view-input {
  position: relative;
  padding: 1rem;
}

.search-view-input__wrapper {
  width: 100%;
  position: relative;
}

input[type='text'].search-view-input__input {
  border: none;
  outline: none;
  display: block;
  font-size: 18px;
  font-family: var(--font-proxima-nova-regular);
  padding: 1.25rem 2rem 8px 0;
  padding-right: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  border-radius: 0;
  color: #1a1a1a;
  border-bottom: 1px solid var(--color-neutral-100);
  width: 100%;
}

input[type='text'].search-view-input__input::placeholder {
  font-size: 18px;
  font-family: var(--font-proxima-nova-regular);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: var(--color-text-standard);
}

.search-view-input__search-input-icon {
  position: absolute;
  right: 0;
  top: 18px;
  width: 20px;
  height: 20px;
}

.search-view-input__delete-icon {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

@media (--tabletAndDesktop) {
  .search-view-input {
    padding: 0 0 0;
    width: 430px;
    max-width: 430px;
    margin: -6px 2rem 0;
  }

  .search-view-input__wrapper {
    position: relative;
    width: 100%;
    max-width: 430px;
  }

  input[type='text'].search-view-input__input {
    height: 36px;
    padding: 1rem 2rem 28px 0;
    border: none;
    outline: none;
    display: block;
    width: 430px;
    font-size: 16px;
    border-bottom: 1px solid #000000;
  }

  input[type='text'].search-view-input__input::placeholder {
    font-size: 16px;
  }

  .search-view-input__search-input-icon {
    position: absolute;
    top: 6px;
    left: unset;
    right: 0px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .search-view-input__delete-icon {
    position: absolute;
    top: 10px;
    right: 0px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
