import {
  computed, Ref,
} from 'vue';
import { bind } from '@drapejs/core';

const tokenRegex = /\{{(.+?)\}}/gi;

function replace(
  textToFormat: string,
  websiteTexts: Ref<{}>,
  tokens?: { [key: string]: string | number },
) {
  if (!tokens) {
    tokens = {};
  }

  return textToFormat.replace(tokenRegex, (_, p1: string) => {
    if (tokens) {
      const token = tokens[p1.toLowerCase()] || tokens[p1];
      if (typeof token !== 'undefined') {
        return token.toString();
      }
    }

    const token = websiteTexts.value?.[p1.toLowerCase()];
    if (typeof token !== 'undefined') {
      return token.toString();
    }

    return `{${p1.toLowerCase()}}`;
  });
}

export function websiteTextFnBuilder(websiteTexts) {
  return (key: string, tokens?: { [key: string]: string | number }) => computed<string>(() => replace(websiteTexts.value?.[key] || key, websiteTexts, tokens));
}

export default function () {
  const channel = bind('__channel');

  return {
    websiteTextFnBuilder,
    websiteText: (key: string, tokens?: { [key: string]: string | number }) => computed<string>(() => {
      const texts = channel.value?.website?.texts;
      return !tokens ? texts?.[key] : replace(texts?.[key] || key, texts, tokens);
    }),
  };
}
