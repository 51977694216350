import { nextTick, onMounted, onUnmounted, Ref } from "vue";

export function useLazyLoad(
  el: Ref<HTMLElement | null>,
  callback: () => void,
  options: { reinitializeOnResize?: boolean } = {}
) {
  let lazyLoadingObserver: IntersectionObserver | null = null;

  const initializeObserver = async () => {
    if (lazyLoadingObserver) {
      lazyLoadingObserver.disconnect();
    }

    if (el.value && 'IntersectionObserver' in window) {
      await nextTick();
      lazyLoadingObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
            observer.disconnect();
          }
        });
      }, {
        rootMargin: '25000px 0px 400px 0px',
      });

      lazyLoadingObserver.observe(el.value);
    } else {
      callback();
    }
  };

  onMounted(() => {
    initializeObserver();
    if (options.reinitializeOnResize) {
      window.addEventListener('resize', initializeObserver);
    }
  });

  onUnmounted(() => {
    lazyLoadingObserver?.disconnect();
    if (options.reinitializeOnResize) {
      window.removeEventListener('resize', initializeObserver);
    }
  });
}
