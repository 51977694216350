<script>
import {
  fetchPageBuilder,
  navigateKey,
  routeKey,
  OptionsComponent,
} from '@drapejs/core';

import { search } from '@distancify/drapejs-litium';
import { useGA4 } from '@distancify-storefront/tracking-gtm';
import { isSearchOpenKey, searchStringKey, searchResultKey } from '@/keys';
import { registerModalKey, unregisterModalKey } from '@/composables/useModals';
import SearchViewPhone from './SearchViewPhone.vue';
import SearchViewDesktop from './SearchViewDesktop.vue';
import { useCartFunctions } from '@/composables/useCartFunctions';

const quickSearchProductCount = 20;
const ga4 = useGA4({
  useLegacyEnhancedECommerce: true
});

export default {
  extends: OptionsComponent,
  components: {
    SearchViewPhone,
    SearchViewDesktop,
  },
  data: () => ({
    searchResult: null,
    searchString: '',
    cancelPendingRequests: false,
  }),
  inject: {
    navigate: navigateKey,
    route: routeKey,
    registerModal: registerModalKey,
    unregisterModal: unregisterModalKey,
    isSearchOpen: isSearchOpenKey,
    $searchStringReactive: searchStringKey,
    $searchResultReactive: searchResultKey,
  },
  setup() {
    const { isCartOpen } = useCartFunctions();
    return {
      isCartOpen
    };
  },
  watch: {
    route: {
      handler() {
        if (this.isSearchOpen) {
          this.pushSearchEvent();
        }
        this.closeSearch();
      },
      deep: true,
    },
    isSearchOpen(curr) {
      if (curr) {
        this.registerModal('SearchView');
      }
      if (!curr) {
        this.unregisterModal('SearchView');
      }
    },
    searchString() {
      this.$searchStringReactive = this.searchString;
    },
    searchResult() {
      this.$searchResultReactive = this.searchResult;
    },
    isCartOpen(isOpen) {
      if (isOpen) {
        this.closeSearch();
      }
    }
  },
  computed: {
    rootCategory() {
      return this.$channel?.rootProductsCategory || {};
    },
  },
  methods: {
    openSearchView() {
      this.isCartOpen = false;
      this.searchResult = null;
      this.isSearchOpen = !this.isSearchOpen;
    },
    async applyFacetSuggestion(facet) {
      if (!this.rootCategory?.url) {
        return;
      }
      const query = {
        facets: `${facet.facetId}:${facet.facetValueId}`,
      };

      this.$mitt.emit('clear-search-input');

      await this.goToProductsRootPage(query);
    },
    async performQuickSearch(searchString) {

      //HACK 21/08/2024: prevent double search from mobile and desktop
      if(this.searchString === searchString) {
        return;
      }

      this.searchString = searchString;

      if (searchString === '') {
        this.searchResult = null;
        return;
      }

      if (!this.rootCategory.id) {
        return;
      }

      const request = fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
          search: searchString,
        },
        '',
      );

      request.take = quickSearchProductCount;
      request.categorySystemId = this.rootCategory.id;

      const searchResult = await this.$invoke(
        search.commands.performQuickSearch,
        request,
      );

      if (this.searchString !== '') {
        this.searchResult = searchResult;
        this.searchResult.searchString = searchString;
      }
    },
    async performFullSearch(searchString) {
      if (!this.rootCategory?.url) {
        return;
      }

      const query = { ...this.$route.query };
      delete query.search;

      if (searchString) {
        query.search = searchString.toLowerCase();
      }
      this.$mitt.emit('clear-search-input');

      await this.goToProductsRootPage(query);
    },
    buildUrlQueryString(query) {
      const querySegments = [];
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value) {
          querySegments.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      return querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
    },
    async goToProductsRootPage(query) {
      const querySegments = [];
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value) {
          querySegments.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      const queryString = querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
      await this.navigate(`${this.rootCategory.url}${queryString}`);
    },
    closeSearch() {
      this.isSearchOpen = false;
      this.searchString = '';
    },
    pushSearchEvent() {
      ga4.search(this.searchString, {
        search_hit_count_products: this.searchResult?.productSearchV2?.hits || 0,
        search_hit_count_categories: this.searchResult?.categorySearch?.hits || 0,
        search_hit_count_pages: this.searchResult?.pageSearch?.hits || 0
      });
    },
  },
  mounted() {
    this.$mitt.on('open-search-view', this.openSearchView);

    this.$mitt.on('perform-quick-search', this.performQuickSearch);
    this.$mitt.on('perform-full-search', this.performFullSearch);
    this.$mitt.on('apply-facet-suggestion', this.applyFacetSuggestion);
    this.$mitt.on('close-search', this.closeSearch);
    this.$mitt.on('search-performed', this.pushSearchEvent);
    this.$mitt.on('search-result-clicked', this.pushSearchEvent);
  },
  beforeUnmount() {
    this.$mitt.off('open-search-view', this.openSearchView);

    this.$mitt.off('perform-quick-search', this.performQuickSearch);
    this.$mitt.off('perform-full-search', this.performFullSearch);
    this.$mitt.off('apply-facet-suggestion', this.applyFacetSuggestion);
    this.$mitt.off('close-search', this.closeSearch);
    this.$mitt.off('search-performed', this.pushSearchEvent);
    this.$mitt.off('search-result-clicked', this.pushSearchEvent);
  },
};
</script>

<template>
  <div class="search-view">
    <search-view-desktop />
    <search-view-phone />
  </div>
</template>
