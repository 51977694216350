<script>
import { batch } from '@distancify/drapejs-litium';
import { invokeKey, getItemKey, setItemKey } from '@drapejs/core';
import { gql } from 'graphql-request';
import {
  defineComponent,
  inject,
  onServerPrefetch,
  ssrContextKey,
  provide,
} from 'vue';
import mitt from 'mitt';
import { emitterKey } from '@/keys';

import { getCookies } from './utils';

import Layout from './components/Layout.vue';

export default defineComponent({
  name: 'App',
  components: {
    Layout,
  },
  setup() {
    const invoke = inject(invokeKey);
    const getItem = inject(getItemKey);
    const setItem = inject(setItemKey);
    const ssrContext = inject(ssrContextKey, undefined);
    const emitter = mitt();

    provide(emitterKey, emitter);

    onServerPrefetch(async () => {
      await Promise.all(scheduleQueries());
    });

    if (!ssrContext?.req) {
      // If we're in the browser, trigger refresh of cart and channel. These are fetched from onServerPrefetch on SSR.
      scheduleClientQueries();
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('ga4_client_id_update', (e) => {
        trySaveGa4Headers();
      });
      window.addEventListener('ga4_session_id_update', (e) => {
        trySaveGa4Headers();
      });

      window.addEventListener("fb_loaded", (e) => {
        trySaveMetaHeaders();
      })
      trySaveGa4Headers();
    }

    function scheduleQueries() {
      return [
        invoke(batch.scheduleQuery, {
          query: gql`
                channel {
                  website {
                    texts(prefix:[])
                    systemId
                    favIconImageId
                    shippingUsp {
                      tag
                      title
                    }
                    maxNumberOfRecentlyVisitedProducts
                  }
                  rootProductsCategory
                  frameworkContentJson
                  isStoreEnabled
                  voyadoConsentHtml
                  cartUsp
                  rootPath
                  locale
                  systemId
                  id
                  metaProduct {
                    fieldsJson
                  }
                  headerMenu
                  footerLinks
                  footerNewsletterSignup
                  colorsHue
                  sizeNameOverrides
                }
              `,
          cacheKey: '__channel',
        }),
      ];
    }

    function scheduleClientQueries() {
      return [
        ...scheduleQueries(),
        invoke(batch.scheduleQuery, {
          query: gql`
            countrySwitcher {
              geoIpCountryCode
              availableCountries {
                supportsEcommerceFeatures
                channelSystemId
                supportsDelivery
                paymentMethodImages {
                  id
                  alt
                }
                name
                rootPath
                current
                countryId
                culture
                currencySymbol
                currencyId
                englishUsName
              }
            }`,
          cacheKey: '__countrySwitcher',
        }),
      ];
    }

    async function trySaveGa4Headers() {
      if (!window.__ga4_client_id && !window.__ga4_client_id) return;
      let litiumOptions = (await getItem('__litium_options')) || {};

      litiumOptions.additionalHeaders = {
        ...litiumOptions.additionalHeaders,
        'LH-GA4ClientId': window.__ga4_client_id,
        'LH-GA4SessionId': window.__ga4_session_id,
      };

      await setItem('__litium_options', litiumOptions);
    }

    async function trySaveMetaHeaders() {
      const cookies = getCookies();

      if(!cookies['_fbp']){
        setTimeout(() => {
          trySaveMetaHeaders();
        }, 500);
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const clickId = urlParams.get('clickId');

      let litiumOptions = (await getItem('__litium_options')) || {};

      litiumOptions.additionalHeaders = {
        ...litiumOptions.additionalHeaders,
        'LH-FBP': cookies?.['_fbp'] || '',
        'LH-FBC': cookies?.['_fbc'] || clickId || '',
      };
      await setItem('__litium_options', litiumOptions);
    }


  },
  provide() {
    return {
      $formatPrice: this.$formatPrice,
    };
  },
});
</script>

<template>
  <router-view>
    <layout />
  </router-view>
</template>
