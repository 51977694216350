<script>
  import ProductCard from '../ProductCard';
  import useWebsiteTexts from '@/composables/useWebsiteTexts';

  import { searchMixin } from './search';

  import ExpandableList from './ExpandableList.vue';

  export default {
    components: {
      ProductCard,
      ExpandableList,
    },
    mixins: [searchMixin],
    setup() {
      const { websiteText } = useWebsiteTexts();

      return {
        websiteText,
      };
    },
    methods: {
      registerProductSearchResultClick() {
        this.$mitt.emit('search-result-clicked');
        this.$mitt.emit('clear-search-input');
      },
    },
  };
</script>

<template>
  <div class="search-view-results">
    <div class="search-view-results__links">
      <div class="search-view-results__facets">
        <div class="search-view-results__facet-suggestions">
          <template
            v-for="facet in facetSuggestions"
            :key="facet.field"
            class="search-view-results__facet-suggestion">
            <div
              v-for="facetValue in facet.suggestedValues"
              :key="facetValue.value"
              class="search-view-results__facet-value-suggestion"
              @click="
                $mitt.emit('apply-facet-suggestion', {
                  facetId: facet.field,
                  facetValueId: facetValue.value,
                })
              ">
              {{ facetValue.label }}
            </div>
          </template>
        </div>
      </div>

      <ExpandableList
        v-if="categoryHits"
        :caption="websiteText('search__category_results_title').value || 'search__category_results_title'"
        :items="categories"
        :getItemName="getCategoryName" />

      <ExpandableList
        v-if="pageHits"
        :caption="websiteText('search__page_results_title').value || 'search__page_results_title'"
        :items="pages"
        :getItemName="(page) => page.pageName" />

      <div></div>
      <div
        class="search-view-results__all-product-hits"
        v-if="productHits"
        @click="performFullSearch()">
        {{ productHitsLabel }}
      </div>
    </div>
    <div class="search-view-results__products-wrapper">
      <div
        class="search-view-results__products"
        v-if="products.length > 0">
        <product-card
          class="search-view-results__product"
          v-for="product in products"
          :key="product.articleNumber"
          :product="product"
          :hide-related-articles="true"
          @click="registerProductSearchResultClick()" />
      </div>
    </div>
  </div>
  <div
    class="search-view-results__no-result"
    v-if="!hasAnySearchResults">
    <div>
      {{ websiteText('search__no_hits').value }}
    </div>
  </div>
</template>

<style>
  .search-view-results {
    background-color: white;
    margin-top: 1.25rem;
    display: flex;
    max-height: calc(100vh - 320px);
  }

  .search-view-results__links {
    width: 30%;
    margin: 0.5rem 0;
    padding-left: 15px;
    overflow-y: auto;
  }

  .search-view-results__page {
    padding-right: 0.5rem;
  }

  .search-view-results__all-product-hits {
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-proxima-nova-bold);
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 0.5rem;
  }

  .search-view-results__facets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  .search-view-results__facet-suggestions {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .search-view-results__facet-suggestion {
    display: flex;
    flex-wrap: wrap;
  }

  .search-view-results__facet-value-suggestion {
    color: white;
    background-color: var(--color-neutral-100);
    border: 1px solid black;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-proxima-nova-regular);
  }

  .search-view-results__page-suggestion,
  .search-view-results__page-suggestion-text {
    padding-bottom: 0.75rem;
    text-decoration: none;
    font-family: var(--font-proxima-nova-regular);
    font-size: 16px;
  }

  .search-view-results__page-suggestion:hover {
    text-decoration: underline;
  }

  .search-view-results__page-caption {
    font-size: 14px;
    font-family: var(--font-proxima-nova-light);
    text-transform: uppercase;
    color: var(--color-text-standard);
    line-height: 24px;
    padding-bottom: 0.35rem;
  }

  .search-view-results__page-suggestion {
    font-size: 14px;
    font-family: var(--font-proxima-nova-regular);
    color: #1a1a1a;
  }

  .search-view-results__no-result {
    text-align: center;
    font-family: var(--font-proxima-nova-bold);
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 2rem;
  }

  .search-view-results__no-result-image {
    padding: 1.5rem;
  }

  .search-view-results__products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 20px;
    margin: 0 0 1rem;
    width: 100%;
  }

  .search-view-results__products-wrapper {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    max-height: calc(100vh - 320px);
    overflow-y: auto;
  }

  .search-view-results__product {
    min-width: 25%;
  }
</style>
