import { inject, ref, ssrContextKey } from 'vue';
import { Emitter } from 'mitt';
import { invokeKey, pageKey, routeKey } from '@drapejs/core';
import { replaceTokensKey, emitterKey, cartKey, channelKey } from '@/keys';

export default function () {
  const channel = inject(channelKey, ref(null));
  const cart = inject(cartKey, ref(null));

  function formatPrice(
    value: number,
    decimals?: number,
    locale?: string,
    currencyId?: string
  ) {
    if (!decimals) {
      decimals = 2;
    }
    if (value !== 0 && !value) {
      return '';
    }
    if (!cart.value) {
      return value;
    }

    return new Intl.NumberFormat(locale || channel?.value?.locale || 'sv-SE', {
      style: 'currency',
      currency: currencyId || cart?.value?.currency?.id || 'SEK',
      maximumFractionDigits: decimals,
      minimumFractionDigits: 0,
    }).format(value);
  }

  return {
    invoke: inject(invokeKey, () => Promise.resolve()),
    page: inject(pageKey, ref()),
    ssrContext: inject(ssrContextKey, undefined),
    route: inject(routeKey, <any>{}),
    replaceTokens: inject(replaceTokensKey, () => ''),
    mitt: inject(emitterKey, <Emitter<any>>(<any>{})),
    cart: cart,
    channel: channel,
    formatPrice: formatPrice,
  };
}
